body {
  background: black;
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.ant-modal-header {
  border-bottom: none !important;
}
.ant-modal-footer {
  border-top: none !important;
}

.mono {
  font-family: sfmono-regular,Consolas,liberation mono,Menlo,Courier,monospace;
}

.modal-video-close-btn {
  cursor: pointer;
}
